@import url(https://fonts.googleapis.com/css2?family=Gothic+A1&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2B2B2B;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  font-family: 'Gothic A1', sans-serif;
  box-sizing: border-box;
}

.yellow{
  color: #FFC66D;
}

.white{
  color: #FFFFFF;
}

.thic{
  letter-spacing: 3px;
}

.error{
  margin-top: -20px;
  color: red;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.big-container {
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  overflow-x: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
}

.top-shadow, .bottom-shadow{
  width: 100%;
  position: fixed;
  height: 20px;
  z-index: 50;
}

.top-shadow{
  background-image: linear-gradient(to top, rgba(255,0,0,0), rgba(30,30,30,0.6));
}

.bottom-shadow{
  bottom: 0;
  background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(30,30,30,0.6));
}

.mid-contaier{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* grid-template-columns: 0.8fr 1.2fr; */
  max-width: 1400px;
  width: 100vw;
}

.section-container {
  display: flex;
  flex-direction: column;
  padding: 90px 50px 0 50px;
}

.left-container {
  pointer-events: none;
  height: 100vh;
  position: fixed;
  padding-right: 0px;
  align-items: flex-start;
  justify-content: space-between;
}

.right-container {
  max-width: 836px;
  position: absolute;
  right: 0;
  padding-left: 0px;
  align-items: flex-end;
}

.intro .title {
  -webkit-transform-origin-x: 0;
  transform: scale(1.23, 1.1);
  display: flex;
  font-size: 3em;
  font-weight: bold;
  color: #a9b7c5;
  margin-bottom: 3px;
}

.intro .subtitle {
  font-size: 0.9em;
}

.intro .i-text {
  margin-top: 5px;
  white-space: pre-wrap;
  color: #a9b7c5;
  font-size: 1.75em;
  max-width: 380px;
}

.menu {
  margin-top: 50px;
  margin-bottom: 50px;
}

.option {
  pointer-events: all;
  cursor: pointer;
  display: flex;
  color: #a9b7c5;
  margin-top: 10px;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.opt-separator {
  width: 100px;
  height: 1px;
  background-color: #a9b7c5;
  margin-left: 5px;
  margin-right: 5px;
  transition: all 0.2s ease-in-out;
}

.footer-path {
  padding-bottom: 50px;
  display: flex;
}

.footer-path > .photo {
  overflow: hidden;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  border: solid 4px #ffc66d;
}

.footer-path > .photo > img {
  width: 50px;
}

.social {
  pointer-events: all;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.s-network {
  display: flex;
  align-items: center;
  background-color: #232325;
  padding: 10px;
  color: white;
  cursor: pointer;
}

.s-network:hover {
  background-color: #111111;
}

.s-n-ico {
  text-align: center;
  font-size: 0.8em;
  margin: 10px;
}

.uncutable {
  overflow: hidden;
}

.sec-title{
  margin-bottom: 20px;
  display: none;
}

.section-space{
  margin-bottom: 30px;
}

.selected{
  color: white;
  font-size: 2em;
}

.selected .opt-separator{
  height: 2px;
  background-color: white;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2B2B2B;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 1300px) {
  .section-container {
    padding: 20px 20px 0 20px;
  }
}

@media (max-width: 1150px) {

  .sec-title{
    display: block;
  }

  .mid-contaier{
    flex-direction: column;
  }

  .section-container{
    width: 100% !important;
  }

  .left-container {
    position: relative;
  }
  
  .right-container {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    position: relative;
  }

  .intro{
    margin-bottom: 40px;
  }

  .intro .i-text-container {
    display: flex;
    flex-wrap: wrap;
  }

  .menu {
    display: none;
  }
}

@media (max-width: 600px) {

  
  .intro .title {
    margin-top: 15px;
    font-size: 3em;
    margin-bottom: 10px;
  }

  .intro{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .intro .i-text-container {
    flex-direction: column;
    align-items: center;
  }

  .intro .i-text {
    font-size: 1.2em;
  }

  .right-container {
    padding-top: 0 ;
  }

  .top-path{
    width: 100%;
  }

  .s-network {
    padding: 2px;
  }

  .footer-path{
    width: 100%;
  }

  .social{
    width: 100%;
    margin: 0;
  }

  .s-network {
    width: 100%;
  }

  .footer-path .photo{
    display: none;
  }

  .title-container{
    /* margin-left: -45px; */
  }
}
.card{
    /* transition: all 0.2s ease-in-out; */
    background-color:#232325;
    padding: 2px;
    margin-bottom: 10px;
    min-width: 400px;
    width: 90%;
    z-index: 2;
}

.card:hover{
    background-color: #202022;
}

@media (min-width: 1300px) {
    .card {
      min-width: 600px;
      width: 90%;  
    }
}

@media (max-width: 1150px) {
    .card {
        min-width: 300px;
        width: 100%;  
      }
    
  }
.p-section {
  width: 100%;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.project-structure {
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.left {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right {
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
}

.p-langs {
  font-size: 0.8em;
  letter-spacing: 3px;
}

.p-title {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 2em;
  font-weight: 600;
  color: white;
}

.p-desc {
  font-size: 0.8em;
  color: #949495;
}

.img {
  transition: all 0.2s ease-in-out;
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.frame {
  opacity: 0.3;
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
  position: absolute;
}

.project-structure:hover .img {
  width: 150px;
  height: 150px;
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
}

.project-structure:hover .frame {
  opacity: 0;
}

.project-structure:hover .right {
  padding: 5px;
}

.more-btn{
  border: solid 2px #FFC66D;
  padding: 10px;
  font-size: 1.2rem;
  cursor: pointer;
  color: #FFC66D;
}

@media (max-width: 1150px) {
  .p-section {
    align-items: center;
  }
}

@media (max-width: 600px) {


  .p-title {
    font-size: 1.5em;
  }

  .right {
    display: none;
  }
}

.a-section {
  width: 100%;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.a-title {
  letter-spacing: 3px;
  font-size: 0.9em;
  color: #ffc56c;
}

.a-desc {
  font-size: 1em;
  text-align: left;
  color: white;
}

.about-structure {
  padding: 20px;
}

.a-photo {
  width: 100%;
  margin: 20px 0 20px 0;
}

.a-photo > img {
  transition: all 0.2s ease-in-out;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  width: 100%;
}

.a-section:hover img {
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
}

.a-desc{
    margin: 10px 0 10px 0;
}

.a-techs{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.a-tech{
    color: white;
}

@media (max-width: 1150px) {
  .a-section {
    align-items: center;
  }
}

@media (max-width: 600px) {
  .a-desc {
    font-size: 0.8em;
  }
}

.c-section {
  width: 100%;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.contact-structure {
  position: relative;
  overflow: hidden;
  padding: 20px;
  color: white; 
  display: flex;
  flex-direction: column;
}

.sending-loader{
  position: absolute;
  left: 0;
  top: 0;
  letter-spacing: 3px;
  font-size: 1.5em;
  background-color: rgba(43, 43, 43, 0.9);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  justify-content: flex-start;
  align-items: center;
}

.sent{
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding: 10px;
  background-color: #558651;
}

.margin5{
  margin-bottom: 10px;
}
.margin10{
  margin-bottom: 25px;
}

.c-title{
  letter-spacing: 3px;
  font-size: 0.9em;
}

.custom-input{
  background-color: #A9B7C6;
  padding: 5px;
  border: none;
  font-weight: bold;
}

.custom-button{
  align-self: flex-end;
  color: #ffc56c;
  border: none;
  background-color: #2B2B2B;
  cursor: pointer;
  padding: 15px;
  width: 150px;
}

@media (max-width: 1150px) {
  .c-section {
    align-items: center;
  }
}


@media (max-width: 600px) {
  .c-text {
    font-size: 0.8em;
  }
}


/* -----------------Spinner-------------------- */

.spinner {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.cube1, .cube2 {
  background-color: white;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
  animation: sk-cubemove 1.8s infinite ease-in-out;
}

.cube2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-cubemove {
  25% { -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5) }
  50% { -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg) }
  75% { -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5) }
  100% { -webkit-transform: rotate(-360deg) }
}

@keyframes sk-cubemove {
  25% { 
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  } 50% { 
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  } 50.1% { 
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  } 75% { 
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
  } 100% { 
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}
.shp1 {
  fill: transparent;
  stroke-dasharray: 140 540;
  stroke-dashoffset: -300;
  stroke-width: 100px;
  stroke: #19f6e8;
}

.container {
  z-index: 70;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  transition: all 0.2s ease-in-out;
  flex-direction: column;
}

@-webkit-keyframes draw {
  0% {
    stroke-dasharray: 140 540;
    stroke-dashoffset: -400;
  }
  100% {
    stroke-dasharray: 760;
    stroke-dashoffset: 0;
  }
}

@keyframes draw {
  0% {
    stroke-dasharray: 140 540;
    stroke-dashoffset: -400;
  }
  100% {
    stroke-dasharray: 760;
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes draw2 {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes draw2 {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes draw3 {
  0% {
    stroke: #000000;
  }
  50% {
    stroke: #000000;
  }
  100% {
    stroke: #FFC66D;
  }
}

@keyframes draw3 {
  0% {
    stroke: #000000;
  }
  50% {
    stroke: #000000;
  }
  100% {
    stroke: #FFC66D;
  }
}

@-webkit-keyframes draw4 {
  0% {
    fill: transparent;
  }
  50% {
    fill: transparent;
  }
  100% {
    fill:#FFC66D;
  }
}

@keyframes draw4 {
  0% {
    fill: transparent;
  }
  50% {
    fill: transparent;
  }
  100% {
    fill:#FFC66D;
  }
}

@-webkit-keyframes increase {
  0% {
    transform: scale(1,1);
  }
  30% {
    transform: scale(1,1);
  }
  100% {
    transform: scale(1.1,1.1);
  }
}

@keyframes increase {
  0% {
    transform: scale(1,1);
  }
  30% {
    transform: scale(1,1);
  }
  100% {
    transform: scale(1.1,1.1);
  }
}

.loading-text {
  -webkit-animation: 6s draw2 linear forwards;
  animation: 6s draw2 linear forwards;
}

.txt {
  -webkit-animation: 2s draw linear forwards, 4s draw3 linear forwards,
    4s draw4 linear forwards;
  animation: 2s draw linear forwards, 4s draw3 linear forwards,
    4s draw4 linear forwards;
}

.shp1 {
  -webkit-animation: 2s draw linear forwards, 4s draw3 linear forwards;
  animation: 2s draw linear forwards, 4s draw3 linear forwards;
}

.loader-svg{
  -webkit-animation: 12s increase linear forwards;
  animation: 12s increase linear forwards;
}

.loaded {
  opacity: 0;
  pointer-events: none;
}

