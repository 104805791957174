.p-section {
  width: 100%;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.project-structure {
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.left {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right {
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
}

.p-langs {
  font-size: 0.8em;
  letter-spacing: 3px;
}

.p-title {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 2em;
  font-weight: 600;
  color: white;
}

.p-desc {
  font-size: 0.8em;
  color: #949495;
}

.img {
  transition: all 0.2s ease-in-out;
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: grayscale(100%);
}

.frame {
  opacity: 0.3;
  filter: grayscale(0%);
  position: absolute;
}

.project-structure:hover .img {
  width: 150px;
  height: 150px;
  filter: grayscale(0%);
}

.project-structure:hover .frame {
  opacity: 0;
}

.project-structure:hover .right {
  padding: 5px;
}

.more-btn{
  border: solid 2px #FFC66D;
  padding: 10px;
  font-size: 1.2rem;
  cursor: pointer;
  color: #FFC66D;
}

@media (max-width: 1150px) {
  .p-section {
    align-items: center;
  }
}

@media (max-width: 600px) {


  .p-title {
    font-size: 1.5em;
  }

  .right {
    display: none;
  }
}
