.card{
    /* transition: all 0.2s ease-in-out; */
    background-color:#232325;
    padding: 2px;
    margin-bottom: 10px;
    min-width: 400px;
    width: 90%;
    z-index: 2;
}

.card:hover{
    background-color: #202022;
}

@media (min-width: 1300px) {
    .card {
      min-width: 600px;
      width: 90%;  
    }
}

@media (max-width: 1150px) {
    .card {
        min-width: 300px;
        width: 100%;  
      }
    
  }